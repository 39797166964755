
import classnames from 'classnames'
import React from 'react'
import { Container } from 'react-bootstrap'
import seven_content1 from '../../resources/images/seven_content1.png'
import seven_content2 from '../../resources/images/seven_content2.png'
import seven_content3 from '../../resources/images/seven_content3.png'
import seven_lc1 from '../../resources/images/seven_lc1.png'
import seven_lc2 from '../../resources/images/seven_lc2.png'
import seven_lc3 from '../../resources/images/seven_lc3.png'
import seven_bottom_bg from '../../resources/images/seven_bottom_bg.png'
import seven_title_icon from '../../resources/images/seven_title_icon.png'

import './body.css'
import { useTranslation, getI18n } from 'react-i18next'
import { useContext } from 'react'
import StoreContext from '../store';
const BodyContext = () => {
    const { t } = useTranslation()
    const { language } = getI18n()
    const isTHLanguage = language == 'th'
    const isMXLanguage = language == 'mx'
    const store: any = useContext(StoreContext)
    return <>
        <Container fluid={true} className='p-t-58 px-0' id='home'>
            <div className='maxboxxsix' id='home'>
                <div className='maxbox' >
                    <div className='box_seven' >
                        <div className='seven_txt_six'>
                            {t('全球领先的贷款服务')}
                        </div>
                        <div className='seven_content_txt'>{t('最高可借')}{t('80000')}{t('฿')}</div>
                        <div className='bt_ljty_seven'>{t('立即申请')}</div>
                    </div>

                </div>
            </div>
            <div className='seven_box_color'>
                <div className='conten_box_seven'>
                    <div className='title_box'>
                        <div className='title_txt'>{t('我们的优势')}</div>
                        <img className='title_bottom_icon' src={seven_title_icon} />
                    </div>

                    <div className='conten_txt_box'>
                        <div className='neirong_box'>
                            <div className='neirong_list'>
                                <div className='neirong_left' style={{ display: "flex", alignItems: "center" }}>
                                    <div className='top_txt'>{t('易申请')}</div>
                                    <div className='bottom_txt'>{t('3步走完流程')}</div>
                                </div>
                                <div className='neirong_right'>
                                    <img className='title_bottom_icon' src={seven_content1} />
                                </div>
                            </div>
                            <div className='neirong_list'>

                                <div className='neirong_right' style={{ display: "flex", alignItems: "center" }}>
                                    <img className='title_bottom_icon' src={seven_content2} />
                                </div>
                                <div className='neirong_left'>
                                    <div className='top_txt'>{t('秒审批')}</div>
                                    <div className='bottom_txt'>{t('30秒审批')}</div>
                                </div>
                            </div>
                            <div className='neirong_list'>
                                <div className='neirong_left' style={{ display: "flex", alignItems: "center" }}>
                                    <div className='top_txt'>{t('高额度')}</div>
                                    <div className='bottom_txt'>{t('最高可借')}80000</div>
                                </div>
                                <div className='neirong_right'>
                                    <img className='title_bottom_icon' src={seven_content3} />
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
            <div className='seven_lc_box_bg'>
                <div className='seven_lc_box'>
                    <div className='title_box'>
                        <div className='title_txt'>{t('我们的优势')}</div>
                        <img className='title_bottom_icon' src={seven_title_icon} />
                    </div>
                    <div className='seven_lc_box_list'>
                        <div className='seven_lc_list'>
                            <img className='seven_lc_icon' src={seven_lc1} />
                            <div className='seven_lc_top'> {t('全球领先的贷款服务')}</div>
                            <div className='seven_lc_bottom'> {t('全球领先的贷款服务')}</div>
                        </div>
                        <div className='seven_lc_list'>
                            <img className='seven_lc_icon' src={seven_lc2} />
                            <div className='seven_lc_top'> {t('获取额度')}</div>
                            <div className='seven_lc_bottom'> {t('最高获80000')}</div>
                        </div>
                        <div className='seven_lc_list'>
                            <img className='seven_lc_icon' src={seven_lc3} />
                            <div className='seven_lc_top'> {t('提现到账')}</div>
                            <div className='seven_lc_bottom'> {t('最快1分钟到账')}</div>
                        </div>
                    </div>
                </div>
                <div className='seven_bottom_bg'>
                    <div className='seven_bottom_with'>
                        <div className='title_box'>
                            <div className='title_txt'>{t('帮助中心')}</div>
                            <img className='title_bottom_icon' src={seven_title_icon} />
                        </div>
                        <div className='seven_bottom_Txt_box'>
                            <div className='seven_bottom_top_Txt'>  {t('1、申请贷款时，银行流水不够怎么办')}</div>
                            <div className='seven_bottom_bottom_Txt'> {t('提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请')}...</div>
                        </div>
                        <div className='seven_bottom_Txt_box'>
                            <div className='seven_bottom_top_Txt'>  {t('2、申请贷款失败原因主要有哪些')}</div>
                            <div className='seven_bottom_bottom_Txt'> {t('通过审核后最快3分钟到账，发放到银行卡。但因网络原因，到账时间可能会延迟，具体到账时间请以是款银行卡通知为准。')}</div>
                        </div>
                        <div className='seven_bottom_Txt_box'>
                            <div className='seven_bottom_top_Txt'>  {t('3、借款后可以多久到账？')}</div>
                            <div className='seven_bottom_bottom_Txt'> {t('可以进行提前还款，今天借，明天能还。还款时只计算截止到当天的利息。')}</div>
                        </div>
                    </div>

                </div>
            </div>




        </Container>
    </>
}
export default BodyContext