import Header from './head/index'
import React, { createContext, useEffect, useState } from "react"
import { TempleteType } from '../common/enum'
import TempleteOne from './TempleteOne/index'
import TempleteTwo from './TempleteTwo/index'
import TempleteThree from './TempleteThree/index'
import TempleteFour from './TempleteFour/index'
import TempleteFive from './TempleteFive/index'
import TempleteSix from './TempleteSix/index'
import TempleteSeven from './TempleteSeven/index'
import axios from 'axios'
import StoreContext from './store'
import { useTranslation, } from 'react-i18next'
const App = () => {
  let windowData: any = window
  console.log("%c Line:14 🍓 window", "color:#ffdd4d", window);
  const [store, setStore] = useState({});
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setStore(windowData.config)
    i18n.changeLanguage(windowData.config.language)
  }, [])

  const formatTemplete = () => {
    let stores: any = store;
    console.log("%c Line:21 🍯 stores", "color:#ea7e5c", stores);
    let templeteId = stores?.templeteId;
    console.log("%c Line:26 🍺 templeteId", "color:#ea7e5c", templeteId);
    // let templeteId = 4;
    switch (Number(templeteId)) {
      case TempleteType.templeteOne: {
        return <TempleteOne />
      }
      case TempleteType.templeteTwo: {
        return <TempleteTwo />
      }
      case TempleteType.templeteThree: {
        return <TempleteThree />
      }
      case TempleteType.templeteFour: {
        return <TempleteFour />
      }
      case TempleteType.templeteFive: {
        return <TempleteFive />
      }
      case TempleteType.templeteSix: {
        return <TempleteSix />
      }
      case TempleteType.templeteSeven: {
        return <TempleteSeven />
      }


    }
    // return <TempleteFive />
  }
  return (
    <StoreContext.Provider value={store} >
      <Header />
      {formatTemplete()}
    </StoreContext.Provider >

  );
}

export default App;
